import React, { useEffect, useState, useRef, useContext } from 'react';
//@ts-ignore
import * as styles from './topiccallout.module.css';
import { graphql } from 'gatsby';
import ArrowLgButton from '../Global/ArrowLgButton';
import { getMediaData, getMultiChoice } from '../Global/DataUtils/index';
import CtaLink from '../Global/CtaLink';

import {
    BrandColor,
    KontentStringValue,
    CTAConfiguration,
    CTALocation,
    KontentMedia,
} from '../../../types/KontentProps';
import { getColor } from '../Global/DataUtils';

import { BuildLinkHref } from '../Global/BuildLinkHref';
import { getFluidImageData } from '../Global/DataUtils/gatsbyImageUtils';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import { defaultDomainCountryCodes } from '../../global/countries';

type galleryWidth = number;
type left = number;
type width = number;
type cardWidth = number;
type showWidth = number;
type dotsToShow = number;

type element = {
    content_panel__content_panel_color?: BrandColor;
    content_panel__content_panel_font_color?: BrandColor;
    headline: KontentStringValue;
    copy: KontentStringValue;
    primary_image: KontentMedia;
    secondary_image: KontentMedia;
    cta_configuration__cta_window_action: {
        value: [
            {
                name: string;
                codename: string;
            }
        ];
    };
    hide_cta: {
        value: [
            {
                codename: string;
            }
        ];
    };
    cta_configuration__cta_label: KontentStringValue;
    cta_configuration__cta_location: CTALocation;
    cta_configuration__cta_location_anchor_point: KontentStringValue;
};

type card = {
    value: { elements: element }[];
};

type layout = {
    value: {
        name: string;
    }[];
};

interface Props extends CTAConfiguration {
    cards: card;
    layout: layout;
}

const TopicCallOut: React.FC<Props> = ({ cards, layout }) => {
    //corosal view render or not for mobile
    const isCorosal = layout?.value[0]?.name == 'Carousel' ? true : false;
    const [currentOffice, setCurrentOffice] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [width, setWidth] = useState(0);
    const [left, setLeft] = useState(0);
    const galleryWrap: any = useRef(null);
    const pageMetadata = useContext(PageMetadataContext);

    //swipe hand gesture
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    const cardWidth = width > 360 ? 300 : 260;
    const galleryWidth = cards?.value?.length * cardWidth;
    const showWidth = width > 360 ? Math.floor((width - 50) / cardWidth) : 1;
    const dotsToShow =
        width > 360
            ? Math.ceil(galleryWidth / cardWidth / showWidth)
            : cards?.value.length;

    let clickNext = setGalleryPosition(
        galleryWidth,
        width,
        left,
        showWidth,
        cardWidth
    );
    let newGalleryWidth = setNewGalleryWidth(galleryWidth, width, cardWidth);

    //register swipe
    function handleTouchStart(e: any) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e: any) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (touchStart - touchEnd > 150) {
            galleryWidth + 50 > width &&
            currentPosition + 1 < dotsToShow &&
            touchEnd > 0
                ? handleClickNext()
                : null;
        } else if (touchStart - touchEnd < -150) {
            galleryWidth > width && currentOffice > 0
                ? handleClickPrev()
                : null;
        }
    }

    //swipe code end here
    const handleClickNext = () => {
        setCurrentOffice(currentOffice + 1);
        setCurrentPosition(currentPosition + 1);
        setLeft(left - cardWidth * showWidth);
        //to prevent next move from click anywhere on right side/whole div
        setTouchEnd(0);
    };

    const handleClickPrev = () => {
        setCurrentOffice(currentOffice - 1);
        setCurrentPosition(currentPosition - 1);
        setLeft(left + cardWidth * showWidth);
    };

    const renderPrevButton = () => (
        <ArrowLgButton
            strokeColor="#f06623"
            direction="left"
            handleClick={handleClickPrev}
            className={styles.prevArrowButton}
        />
    );

    const renderNextButton = () => (
        <ArrowLgButton
            strokeColor="#f06623"
            direction="right"
            handleClick={handleClickNext}
            className={styles.nextArrowButton}
        />
    );

    const renderDots = (dotsToShow: number, currentPosition: number) => {
        let dots = [];

        for (let i = 0; i < dotsToShow; i++) {
            dots.push(
                <span
                    key={i}
                    style={currentPosition == i ? { background: '#ccc' } : {}}
                ></span>
            );
        }

        return dots;
    };

    useEffect(() => {
        const resizeListener = () => {
            if (isCorosal)
                setWidth(galleryWrap.current.getBoundingClientRect().width);
        };

        resizeListener();
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
    //@ts-ignore
    const renderView = (
        item: { elements: element },
        key: number,
        corosal: boolean
    ) => {
        const {
            copy,
            cta_configuration__cta_location,
            cta_configuration__cta_location_anchor_point,
            cta_configuration__cta_label,
            cta_configuration__cta_window_action,
            headline,
            primary_image,
            secondary_image,
            content_panel__content_panel_color,
            content_panel__content_panel_font_color,
        } = item.elements;
        const isCtaHidden = item?.elements.hide_cta?.value[0]?.codename;
        const contentPanelColor = getColor(content_panel__content_panel_color);
        const contentPanelFontColor = getColor(
            content_panel__content_panel_font_color
        );
        const textColor = contentPanelFontColor ? contentPanelFontColor : '';
        const backgroundColor = contentPanelColor ? contentPanelColor : '';
        const ctaLocation = BuildLinkHref(
            cta_configuration__cta_location,
            cta_configuration__cta_location_anchor_point
        );
        const langPrefix = pageMetadata?.preferredLanguage
            ? defaultDomainCountryCodes.indexOf(
                  pageMetadata.preferredLanguage.toLowerCase()
              ) > -1
                ? ''
                : '/' + pageMetadata?.preferredLanguage?.toLowerCase()
            : '';
        const ctaWindowAction =
            getMultiChoice(cta_configuration__cta_window_action) ===
            'new_window'
                ? '_new'
                : '_self';

        const primaryImage = getFluidImageData(primary_image);

        return (
            <>
                {isCtaHidden == 'yes' && (
                    <style>{`.${styles.card}:hover { 
                        transform: translate3D(0, -1px, 0) scale(1.03);
                        box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07), 1px 6px 12px rgba(39, 44, 49, 0.04);
                        transition: all 0.4s ease;
                        cursor: pointer;
                    }`}</style>
                )}
                <li className={styles.cards__item} key={key}>
                    <a
                        href={langPrefix + ctaLocation}
                        target={ctaWindowAction}
                        className={styles.card}
                    >
                        {primaryImage.source && (
                            <GatsbyImage
                                fluid={primaryImage?.source}
                                alt={primaryImage?.alt}
                                className={styles.cardimage}
                                // fadeIn={false}
                            />
                        )}

                        <div
                            className={styles.card__content}
                            style={{
                                backgroundColor: backgroundColor,
                                color: textColor,
                            }}
                        >
                            {getMediaData(secondary_image)?.url && (
                                <img
                                    src={getMediaData(secondary_image)?.url}
                                    className={styles.bgimage}
                                    alt={
                                        getMediaData(secondary_image)
                                            ?.description
                                    }
                                />
                            )}
                            <div
                                className={styles.card__title}
                                style={{
                                    color: textColor,
                                }}
                            >
                                {headline?.value}
                            </div>
                            <p className={styles.card__text}>{copy?.value} </p>

                            {isCtaHidden !== 'yes' && (
                                <button
                                    className={`${styles.btn}   ${styles.btnblock} `}
                                >
                                    <CtaLink
                                        href={BuildLinkHref(
                                            cta_configuration__cta_location
                                        )}
                                        target={ctaWindowAction}
                                        visualStyle="outlineLight"
                                    >
                                        {cta_configuration__cta_label?.value}
                                    </CtaLink>
                                </button>
                            )}
                        </div>
                    </a>
                </li>
            </>
        );
    };

    const CorosalView = (item: { elements: element }, cardIdx: number) => {
        //corosal view
        return (
            <div className={styles.officeCard} key={cardIdx}>
                <div className={styles.card}>
                    <div className={styles.row}>
                        {renderView(item, cardIdx, true)}
                    </div>
                </div>
            </div>
        );
    };

    const renderMobileView = () => {
        return (
            <div className={`${styles.mobile}`}>
                {cards?.value.map(
                    (item: { elements: element }, index: number) => {
                        return renderView(item, index, false);
                    }
                )}
            </div>
        );
    };

    const renderCorosalView = () => {
        return (
            <div
                ref={galleryWrap}
                className={styles.wrapper}
                onTouchStart={(touchStartEvent) =>
                    handleTouchStart(touchStartEvent)
                }
                onTouchMove={(touchMoveEvent) =>
                    handleTouchMove(touchMoveEvent)
                }
                onTouchEnd={() => handleTouchEnd()}
            >
                <div
                    id={styles.gallery}
                    className={styles.gallery}
                    style={newGalleryWidth}
                >
                    {galleryWidth > width && currentOffice > 0
                        ? renderPrevButton()
                        : null}

                    <div style={clickNext}>
                        {cards?.value.map(
                            (item: { elements: element }, index: number) => {
                                return CorosalView(item, index);
                            }
                        )}
                    </div>

                    {galleryWidth + 50 > width &&
                    currentPosition + 1 < dotsToShow
                        ? renderNextButton()
                        : null}
                </div>
                <div className={styles.dots}>
                    {renderDots(dotsToShow, currentPosition)}
                </div>
            </div>
        );
    };
    return (
        <>
            {!isCorosal ? (
                <div>
                    <ul className={styles.cards}>
                        {cards?.value.map(
                            (item: { elements: element }, index: number) => {
                                return renderView(item, index, false);
                            }
                        )}
                    </ul>
                </div>
            ) : (
                <>
                    <div className={styles.mobile}>
                        {isCorosal ? renderCorosalView() : renderMobileView()}
                    </div>
                    <ul className={` ${styles.cards} ${styles.desktop}`}>
                        {cards?.value.map(
                            (item: { elements: element }, index: number) => {
                                return renderView(item, index, false);
                            }
                        )}
                    </ul>
                </>
            )}
        </>
    );
};

export default TopicCallOut;

function setGalleryPosition(
    galleryWidth: galleryWidth,
    width: width,
    marginLeft: left,
    showWidth: showWidth,
    cardWidth: cardWidth
) {
    let newWidth;

    if (galleryWidth < width) {
        if (width / showWidth > cardWidth) {
            newWidth = galleryWidth + cardWidth;
        } else {
            newWidth = width;
        }
    } else {
        newWidth = galleryWidth;
    }
    const galleryPosition: React.CSSProperties = {
        width: newWidth,
        marginLeft: marginLeft,
        transition: 'margin .5s ease-in-out',
    };

    return galleryPosition;
}

function setNewGalleryWidth(
    galleryWidth: galleryWidth,
    width: width,
    cardWidth: cardWidth
) {
    let showWidth = 1;
    let newGalleryWidth = showWidth * cardWidth;

    if (width > 320) {
        showWidth = Math.floor((width - 50) / cardWidth);
        newGalleryWidth = showWidth * cardWidth;
    }

    const galleryInsideWidth: React.CSSProperties = {
        width: newGalleryWidth,
    };

    return galleryInsideWidth;
}

export const query = graphql`
    fragment TopicCallOut on kontent_item_component___topic_call_outs {
        elements {
            layout {
                value {
                    name
                }
            }
            cards {
                value {
                    ... on kontent_item_content_item___topic_card {
                        id
                        elements {
                            headline {
                                value
                            }
                            hide_cta {
                                value {
                                    codename
                                }
                            }
                            copy {
                                value
                            }
                            cta_configuration__cta_label {
                                value
                            }
                            cta_configuration__cta_location {
                                ...kontentCtaLocation
                            }
                            primary_image {
                                value {
                                    ... on kontent_item_media___image {
                                        id
                                        elements {
                                            file {
                                                value {
                                                    name
                                                    description
                                                    url
                                                    fluid(
                                                        maxWidth: 424
                                                        maxHeight: 260
                                                        quality: 100
                                                    ) {
                                                        ...KontentAssetFluid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            secondary_image {
                                value {
                                    ... on kontent_item_media___image {
                                        id
                                        elements {
                                            file {
                                                value {
                                                    name
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            cta_configuration__cta_location_anchor_point {
                                value
                            }
                            cta_configuration__cta_window_action {
                                value {
                                    name
                                }
                            }
                            content_panel__content_panel_color {
                                value {
                                    ... on kontent_item_list___brand_colors {
                                        id
                                        elements {
                                            color_hex_value {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                            content_panel__content_panel_font_color {
                                value {
                                    ... on kontent_item_list___brand_colors {
                                        id
                                        elements {
                                            color_hex_value {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
